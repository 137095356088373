/* eslint-disable react/display-name */
import React from "react"
import { graphql } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import LearnMoreCTA from "./about/LearnMoreCTA"
import SEO from "../components/seo"
import { getPostObj, wrapSup } from "../utils/utils"

import NuvoImage from "../components/NuvoImage"

import SideCTA from "../components/CTA/SideCTA"

const SingleInstruction = ({ data, pageContext, location }) => {
  const postObj = getPostObj(
    pageContext,
    data.allInstructionsJson.nodes[0],
    data.allSpanishInstructionsJson.nodes[0]
  )
  const { post, language } = postObj

  return (
    <SharedStateProvider>
      <Layout
        className={`single-instruction ${language === "es" ? "es" : ""}`}
        language="en">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          youtubeSchemas={pageContext.youtubeSchemasJson}
          lang={language}
        />
        <div className="columns top-section color-back">
          <div className="column is-4" />
          <div className="column">
            <h1
              style={{ marginTop: 0, marginBottom: 0 }}
              dangerouslySetInnerHTML={{ __html: wrapSup(post.name) }}
            />
            <Button
              buttonText={language === "es" ? "ATRÁS" : "BACK"}
              goBack
              href={
                language === "es"
                  ? "/es/instrucciones/"
                  : "/orthodontics-instructions/"
              }
            />
          </div>
          <div className="column is-4" />
        </div>
        <div className="body-section joshua-tree-content instruction-content">
          {post.youtube && post.mainPhotoPublicId && (
            <div className="columns instruction-image-video">
              <div className="column is-3" />
              <div className="column">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  overVideo
                  language={language}
                  buttonClass="contained">
                  <NuvoImage
                    cloudName="nuvolum"
                    publicId={post.mainPhotoPublicId}
                    useAR
                    width="auto"
                    className="theater-image"
                  />
                </TheaterVideo>
              </div>
              <div className="column is-3" />
            </div>
          )}
          <div className="columns single-instruction-content">
            <div className="column is-3" />
            <div className="column">
              <MarkdownViewer
                className="p-with-ul single-instructions-ul"
                markdown={post.body}
              />
            </div>
            <div className="column is-2" />
            <div className="column is-6 here-to-help">
              {post.pdfId && (
                <SideCTA
                  className="single-instruction__side-cta-wrapper"
                  sideColumnIs={0}
                  subheading={language === "es" ? "Versión PDF" : "PDF Version"}
                  heading={
                    language === "es"
                      ? "Instrucciones de Descarga"
                      : "Download Instructions"
                  }
                  paragraph={
                    language === "es"
                      ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                      : "Take the instructions on the go. Download in PDF format."
                  }
                  buttonText={language === "es" ? "Descargar" : "Download"}
                  buttonUrl={`https://res.cloudinary.com/nuvolum/image/upload/v1578872461/${post.pdfId}.pdf`}
                  download
                />
              )}
            </div>
            <div className="column is-3" />
          </div>
        </div>
        <LearnMoreCTA
          className="sctn"
          colorBack
          post={post}
          language={language}
        />
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    allUniquePagesJson(filter: { template: { eq: "patient-instructions" } }) {
      nodes {
        title
      }
    }
    allInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allSpanishInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
        language
      }
    }
  }
`

export default SingleInstruction
